//import car1 from '../../Utilities/Assits/Images/section1.jpg'
//import car2 from '../../Utilities/Assits/Images/section2.jpg'
//import car3 from '../../Utilities/Assits/Images/section3.jpg'
//import logo from '../../Utilities/Assits/Images/logo5.png'
//import burham from '../../Utilities/Files/UserProfiles/user1.jpg'
import useExternalScripts from "../../Hooks/useExternalScripts"
import bnrimg from '../../Utilities/Assits/Images/section3.jpg'
import preg from '../../Utilities/Assits/Images/preg.jpg'
import legalc from '../../Utilities/Assits/Images/legalc.jpg'
import { Link } from 'react-router-dom'
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { useEffect, useState } from "react"

const Home = (props) => {

let quoteText = {quote: "We FIGHT, they LOSE, you WIN"}
let services = [{serv: "15+",link: "#serv1",details:"years of experience"}, {serv: "500+",link: "#serv2",details:"solved cases"}, {serv: "300+",link: "#serv3",details:"served clients"}]
const [shownData, setData] = useState(false)
const sliderAction = (e) => {
    const slider = document.getElementsByClassName('other-services-tab')[0]
    
    let isDown,startX,scrollLeft
     slider.addEventListener('mousedown', (es)=> {
        isDown = true
        startX = es.pageX - slider.offsetLeft
        scrollLeft = slider.scrollLeft
     })
     slider.addEventListener('mouseleave', () => {
        isDown = false;
     })
     slider.addEventListener('mouseup', () => {
        isDown = false;
     })
     slider.addEventListener('mousemove', (es) => {
        if(!isDown) return;
        es.preventDefault();
        const x = es.pageX - slider.offsetLeft;
        const walk = (x - startX) * 3; //scroll-fast
        slider.scrollLeft = scrollLeft - walk;
      });
}

const sliderBtn = (e) => {
    const slider = document.getElementsByClassName('other-services-tab')[0]
    const sliderTabs = document.querySelectorAll('.services-tabs')
    let tabsCount = 0
    let tabWidth = sliderTabs[0].scrollWidth
    sliderTabs.forEach(() => { tabsCount++} )
    
    if(e.currentTarget.classList.contains('slider-left')) slider.scrollLeft = slider.scrollLeft - tabWidth/1.5
    if(e.currentTarget.classList.contains('slider-right')) slider.scrollLeft = slider.scrollLeft + tabWidth/1.5
}

const showHideTabs = (e) => {
    
    if( e.currentTarget.classList.contains('reversed') ) {
        e.currentTarget.classList.remove('reversed')
        let thisSection = document.getElementsByClassName('other-serivces-block')[0]
        thisSection.classList.remove('shown-section')
        setTimeout(() => {
            setData(false)
        },2000)
    } else {
        e.currentTarget.classList.add('reversed')
        setData(true)
        setTimeout(() => {
            let thisSection = document.getElementsByClassName('other-serivces-block')[0]
            thisSection.classList.add('shown-section')
        },100)

    }
}
    return (
        <div className="main home-view">
            <div className="row g-0">
                <div className={`col-md-12 mx-auto banner2`}>
                    <div className={`row g-0 mx-auto h-100`}>
                        <div className={`col-md-1`}> </div>
                        <div className={`col-md-5 m-auto text-center`}><img className={`banner-image`} src={`${bnrimg}`} alt={`banner1`} /></div>
                        <div className={`col-md-5 m-auto text-center`}>
                            <blockquote>
                            <q className="banner-title text-center">
                                <i>
                                    <span className={`quote-text`}>
                                        {
                                            quoteText.quote
                                        }
                                    </span>
                                </i>
                            </q>
                            <cite>Burham B. Soliman</cite>
                            </blockquote>
                        </div>
                        <div className={`col-md-1`}> </div>
                    </div>

                </div>
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1000 100" preserveAspectRatio="none"> 
                    <path className="wave-svg-shape" opacity="0.33" d="M473,67.3c-203.9,88.3-263.1-34-320.3,0C66,119.1,0,59.7,0,59.7V0h1000v59.7 c0,0-62.1,26.1-94.9,29.3c-32.8,3.3-62.8-12.3-75.8-22.1C806,49.6,745.3,8.7,694.9,4.7S492.4,59,473,67.3z"></path>
                    <path className="wave-svg-shape" opacity="0.66" d="M734,67.3c-45.5,0-77.2-23.2-129.1-39.1c-28.6-8.7-150.3-10.1-254,39.1 s-91.7-34.4-149.2,0C115.7,118.3,0,39.8,0,39.8V0h1000v36.5c0,0-28.2-18.5-92.1-18.5C810.2,18.1,775.7,67.3,734,67.3z"></path>
                    <path className="wave-svg-shape" d="M766.1,28.9c-200-57.5-266,65.5-395.1,19.5C242,1.8,242,5.4,184.8,20.6C128,35.8,132.3,44.9,89.9,52.5C28.6,63.7,0,0,0,0 h1000c0,0-9.9,40.9-83.6,48.1S829.6,47,766.1,28.9z"></path>
                </svg>
                <div className={`col-md-12`}>
                    <div className={`row g-0 m-auto`}>
                        { 
                            services 
                            ? services.map((serv) => {
                                return (
                                    <span key={serv.serv} className={`col-4 m-auto text-center`}>
                                        <Link className={`services-circle`} to={`${serv.link}`}><span><h1 style={{color:"darkgoldenrod",marginTop:".5rem"}}>{serv.serv}</h1><p style={{color:"white"}}>{serv.details}</p></span></Link>
                                    </span>
                                )
                            })
                            : ''
                        }                        
                    </div>
                </div>
            </div>

            <div className="row g-0">
                <div className="section-1">
                    <div className="section-1-top">
                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1000 100" preserveAspectRatio="none">
                            <path className="elementor-shape-fill" d="M500.2,94.7L0,0v100h1000V0L500.2,94.7z"></path>
                        </svg>
                    </div>
                    <div className="section-1-mid">
                        <div className="row g-0">
                            <div className="col-12 row g-1 sec-left">
                                <div className="col-md-5 m-auto text-center d-flex justify-content-center ">
                                    <img className={`sec-images`} src={`${legalc}`} alt={`banner1`} />
                                </div>
                                <div className="col-md-7 m-auto text-center">
                                    <h1 className='field-title'>Consultation</h1>
                                    <p className={`text-start`}>We help you instantly to find the right solution of any arguments, With our professional team, we grauntee that you will find the right answers always and will .</p>
                                    <button className={`read-more-btn`}>Read more</button>
                                </div>
                            </div>

                            <div className='col-12 row g-1 sec-right'>
                                <div className="col-md-5 m-auto text-center d-flex justify-content-center">
                                    <img className={`sec-images`} src={`${preg}`} alt={`banner1`} />
                                </div>
                                <div className="col-md-7 m-auto text-center">
                                <h1 className='field-title'>Property registeration</h1>
                                    <p className={`text-start`}>We make it easy to you to get you properties registered with our professional team who works over the country, within short period</p>
                                    <button className={`read-more-btn`}>Read more</button>
                                </div>
                            </div>
                            <div className="col-12 m-auto text-center">
                                <div className="other-services">
                                    
                                {shownData ? 
                                    <div className={`other-serivces-block`}  >
                                        <FontAwesomeIcon className='slider-left' title="Slide to left" size="xl" icon={`angles-left`} onMouseDown={sliderBtn} />
                                        <div className="other-services-tab" onClick={sliderAction} onMouseDown={sliderAction} >
                                            <div className="services-tabs">
                                                <img className={`serv-images`} src={`${preg}`} alt={`banner1`} />
                                                <button className="read-more-btn">Service</button>
                                            </div>
                                            <div className="services-tabs">
                                                <img className={`serv-images`} src={`${preg}`} alt={`banner1`} />
                                                <button className="read-more-btn">Service</button>
                                            </div>
                                            <div className="services-tabs">
                                                <img className={`serv-images`} src={`${preg}`} alt={`banner1`} />
                                                <button className="read-more-btn">Service</button>
                                            </div>
                                            <div className="services-tabs">
                                                <img className={`serv-images`} src={`${preg}`} alt={`banner1`} />
                                                <button className="read-more-btn">Service</button>
                                            </div>
                                            <div className="services-tabs">
                                                <img className={`serv-images`} src={`${preg}`} alt={`banner1`} />
                                                <button className="read-more-btn">Service</button>
                                            </div>
                                            <div className="services-tabs">
                                                <img className={`serv-images`} src={`${preg}`} alt={`banner1`} />
                                                <button className="read-more-btn">Service</button>
                                            </div>
                                        </div>
                                        <FontAwesomeIcon className='slider-right' title="Arrow to right" size="xl" icon={`angles-right`} onMouseDown={sliderBtn}/>
                                
                                    </div>
                                    : <></>}
                                    
                                    <div className="show-more">
                                        <FontAwesomeIcon className='show-more-arrow' title="Arrow Down" size="xl" icon={`angles-down`} onClick={showHideTabs} />
                                    </div>
                                </div>
                            </div>
                            

                        </div>
                    </div>
                    <div className="section-1-bottom ">
                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1000 100" preserveAspectRatio="none">
                            <path className="elementor-shape-fill" d="M500.2,94.7L0,0v100h1000V0L500.2,94.7z"></path>
                        </svg>
                    </div>
                    
                </div>
                <div className='col-12 row g-1'>
                    <div className='col-6 consult-reservation mx-auto text-center'>
                        <div className='reserve-form'>
                            <form className='reserve-appointment' style={{direction:'ltr'}}>
                                <div className='form-group'>
                                    <label for='appointment_name'>Full name </label>
                                    <input type='text' className='appointment-name' id='appointment_name' name='appointment_name' />
                                </div>
                            </form>
                        </div>
                    </div>
                    <div className='col-6 get-contact mx-auto text-center'>
                        right
                    </div>
                </div>
            </div>


        </div>
    )
}

export default Home;