import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './Components/App/App';
import Loader from './Components/Layout/Loader';
import reportWebVitals from './Utilities/Config/reportWebVitals';

const root = ReactDOM.createRoot(document.getElementById('root'));


root.render(
    <>
        <Loader />
        <App />
    </>

  
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
