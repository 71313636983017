import '../../Utilities/Assits/Styles/App.css'
import '../../Utilities/Assits/Styles/index.css'
import {Outlet} from 'react-router-dom'
import Header from "./Header"
import Footer from "./Footer"

const Layout = () => {

    let langlist = [
        {
            name: 'english',
            sign: 'EN',
            active: true
        },
        {
            name: 'arabic',
            sign: 'AR',
            active: false
        }
    ]


    return (
        <>
            <Header langlist={langlist} dataLang={langlist.filter((a) => a.active === true).map((a) => { return a.sign })}/>
            <Outlet />
            <Footer />
        </>
    )
}

export default Layout;