import '../../Utilities/Assits/Styles/App.css';
import {BrowserRouter as Router, Route , Routes } from 'react-router-dom'
import Layout from '../Layout/Layout'
import Home from '../Home/Home'
import Dashboard from '../Dashboard/Dashoard'
import Admin from '../Dashboard/Admin/Admin'
import User from '../Dashboard/User/User'
import Courts from '../Judges/Judges'
import Partners from '../Partners/Partners'
import Services from '../Advice/Advice'
import Mission from '../Info/Info'
import About from '../About/About'
import Error from '../Layout/Error'

function App() {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<Layout />}>
          <Route index element={<Home />} />
          <Route path="dashboard" element={<Dashboard />}>
            <Route path=":userid/" element={<User />}/>
          </Route>
          <Route path="admin" element={<Admin />} />
          <Route path="courts" element={<Courts />} />
          <Route path="partners" element={<Partners />} />
          <Route path="services" element={<Services />} />
          <Route path="mission" element={<Mission />} />
          <Route path="about" element={<About />} />
          <Route path="*" element={<Error />} />
        </Route>
      </Routes>
    </Router>
  );
}

export default App;
