import {useParams} from 'react-router-dom'
import Home from '../Home/Home';
import Admin from './Admin/Admin';
import User from './User/User';
const Dashboard = () => {
    const {userid} = useParams()
    return (
        
        <div className="component">
            { userid 
            ? userid.toString().toLowerCase() ==="admin" ? <Admin />: <User />
            : <Home />
            }
        </div>
    )
}

export default Dashboard;