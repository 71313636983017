
const Services = () => {
    
    return(
        <div className="error-page">
            <h1>Advice</h1>
        </div>
    )
}

export default Services;