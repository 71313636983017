
const Mission = () => {

    return(
        <div className="error-page">
            <h1>Info</h1>
        </div>
    )
}

export default Mission;