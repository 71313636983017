import logo from '../../Utilities/Assits/Images/logo5.png'
import {Link} from 'react-router-dom'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
const Footer = (props) => {
    return (
        <div id="footer" className={`footer`}>
            <div className="row g-0">
                <div className="col-12">
                    <div className={`fotter-top`}>
                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1000 100" preserveAspectRatio="none"> 
                            <path className="svg-shape-balls" opacity="0.33" d="M473,67.3c-203.9,88.3-263.1-34-320.3,0C66,119.1,0,59.7,0,59.7V0h1000v59.7 c0,0-62.1,26.1-94.9,29.3c-32.8,3.3-62.8-12.3-75.8-22.1C806,49.6,745.3,8.7,694.9,4.7S492.4,59,473,67.3z"></path>
                            <path className="svg-shape-balls" opacity="0.66" d="M734,67.3c-45.5,0-77.2-23.2-129.1-39.1c-28.6-8.7-150.3-10.1-254,39.1 s-91.7-34.4-149.2,0C115.7,118.3,0,39.8,0,39.8V0h1000v36.5c0,0-28.2-18.5-92.1-18.5C810.2,18.1,775.7,67.3,734,67.3z"></path>
                            <path className="svg-shape-balls" d="M766.1,28.9c-200-57.5-266,65.5-395.1,19.5C242,1.8,242,5.4,184.8,20.6C128,35.8,132.3,44.9,89.9,52.5C28.6,63.7,0,0,0,0 h1000c0,0-9.9,40.9-83.6,48.1S829.6,47,766.1,28.9z"></path>
                        </svg>
                    </div>
                </div>
                <div className="col-12">
                    <div className={`fotter-mid`}>
                        <div className={`row g-0`}>
                            <div className={`col-md-4`}>
                                <div className={`footer-img-container`}>
                                    <img className={`mx-auto my-auto footer-img`} src={logo} alt={`Burham Soliman Lawfirm`}/>
                                </div>
                            </div>
                            <div className={`col-md-8`}>
                                <div className={`row g-0`}>
                                    <div className={`col-12`}>
                                        <div className={`footer-section-title`}>
                                            Contact info
                                        </div>
                                    </div>
                                    <div className={`col-6`}>
                                        <div className={`row g-0`}>
                                            <div className={`col-1 footer-main-icons`}><FontAwesomeIcon className={`lcl-icon footer-icons`} icon="location-dot" /></div>
                                            <div className={`col-11 footer-main-items`}>23 Safya Zagloul st. Alsharq - Bur-Sa'eid - EGYPT, P.O 42511</div>
                                            <div className={`col-1 footer-main-icons`}><FontAwesomeIcon className={`mob-icon footer-icons`} icon="fa-solid fa-phone" /></div>
                                            <div className={`col-11 footer-main-items`}><a href='tel:+201102423786'>+20 110 242 3786</a></div>
                                            <div className={`col-1 footer-main-icons`}><FontAwesomeIcon className={`wa-icon footer-icons`}   icon="fa-brands fa-whatsapp" /></div>
                                            <div className={`col-11 footer-main-items`}><a href='https://wa.me/201102423786?txt=Burhams.com' target="_blank" rel="noreferrer">+20 110 242 3786</a></div>
                                        </div>
                                    </div>
                                    <div className={`col-6`}>
                                    <iframe className={`map-location`} src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d426.3084797247048!2d32.307956!3d31.263152000000005!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0x785019490a9b5fc0!2sVodafone!5e0!3m2!1sen!2sus!4v1667233117924!5m2!1sen!2sus" title={`Office Location`} allowFullScreen="" loading="lazy" referrerPolicy="no-referrer-when-downgrade"></iframe>
                                    </div>
                                    <div className={`col-12`}>
                                        <div className={`footer-section-title`}>
                                            Site map
                                        </div>
                                    </div>
                                    <div className={`col-4`}>
                                        Services
                                        <ul className={`footer-links-group`}>
                                            <li className={`footer-link`}><Link to={`/contracts`}>Contracts</Link></li>
                                            <li className={`footer-link`}><Link to={`/propreg`}>Property registeration</Link></li>
                                            <li className={`footer-link`}><Link to={`/consult`}>Consultation</Link></li>
                                        </ul>
                                    </div>
                                    <div className={`col-4`}>
                                        Laws & News
                                        <ul className={`footer-links-group`}>
                                            <li className={`footer-link`}><Link to={`/news`}>Latest news</Link></li>
                                            <li className={`footer-link`}><Link to={`/judges`}>Recent judges</Link></li>
                                            <li className={`footer-link`}><Link to={`/laws`}>Laws updates</Link></li>
                                        </ul>
                                    </div>
                                    <div className={`col-4`}>
                                        Info
                                        <ul className={`footer-links-group`}>
                                            <li className={`footer-link`}><Link to={`/clients`}>Our clients</Link></li>
                                            <li className={`footer-link`}><Link to={`/about`}>About</Link></li>
                                            <li className={`footer-link`}><Link to={`/terms`}>Terms & conditions</Link></li>
                                        </ul>
                                    </div>
                                    
                                    <div className={`col-3`}>
                                        <a href={`https://fb.com/BurhamsLaw`} target='__blank'><FontAwesomeIcon href={`https://fb.com/BurhamsLaw`} title={`fb.com/BurhamsLaw`} className={`fb-icon social-icons`} icon="fa-brands fa-square-facebook" mask="fa-solid fa-square"  /></a>
                                    </div>
                                    <div className={`col-3`}>
                                        <FontAwesomeIcon href={`#`}  title={`Coming soon`} className={`lnkd-icon social-icons`} icon="fa-brands fa-linkedin" mask="fa-solid fa-square"  />
                                    </div>
                                    <div className={`col-3`}>
                                        <a href={`https://x.com/BurhamsLaw`} target='__blank'><FontAwesomeIcon title={`x.com/BurhamsLaw`} className={`twtr-icon social-icons`} icon="fa-brands fa-twitter"/></a>
                                    </div>
                                    <div className={`col-3`}>
                                        <a href={`https://instagram.com/BurhamsLaw`} target='__blank'><FontAwesomeIcon  href={`https://instagram.com/BurhamsLaw`} title={`instagram.com/BurhamsLaw`} className={`insta-icon social-icons`} icon="fa-brands fa-square-instagram" mask="fa-solid fa-square"  /></a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-12">
                    <div className={`fotter-end`}>
                        All rights are reserved to <Link to={`/`}>Burhams</Link> ltd. &#169; 2020 - 2022
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Footer;