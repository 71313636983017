import 'bootstrap/dist/css/bootstrap.min.css'
import 'bootstrap/dist/js/bootstrap.bundle.min'
import '../../Utilities/Assits/Styles/Themes/Dark/style.css'
import '../../Utilities/Assits/Styles/Themes/Light/style.css'
import femalepic from '../../Utilities/Assits/Images/femaledefault.png'
import malepic from '../../Utilities/Assits/Images/maledefault.jpg'
import burham from '../../Utilities/Files/UserProfiles/user1.jpg'
import logo from '../../Utilities/Assits/Images/logo5.png'
import {Link} from 'react-router-dom'
import { library } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import * as Icons from '@fortawesome/free-solid-svg-icons';
import * as Icons2 from '@fortawesome/free-brands-svg-icons'

export const iconList = Object
  .keys(Icons)
  .filter(key => key !== "fas" && key !== "prefix" )
  .map(icon => Icons[icon])
export const brandList = Object
  .keys(Icons2)
  .filter(key => key !== "fa" && key !== "prefix" )
  .map(icon => Icons2[icon])

library.add(...iconList,...brandList)

const Header = (props) => {
    let user 
    // = {
    //     username: 'Admin',
    //     sex: 'Male',
    //     picture: burham
    // }
    let {dataLang,langlist} = props

    const setActiveLang = (e) => {
        document.getElementsByClassName('active-lang')[0].classList.remove('active-lang')
        e.currentTarget.classList.add('active-lang')
    }
    return (
            <div className={`header`}>
                <div className={`infobar`}>
                    <div className='row g-0'>
                        <div className={`col-2 col-md-1 my-auto theme-options`} style={{color:"white",fontFamily:" Teko, sans-serif"}}>
                            <div className={`lang-toggler`}>
                            {langlist.map((a) => {
                                return (<span key={a.sign} onClick={setActiveLang} className={` langs-tab ${a.active ? `active-lang` : `other-lang`}`}>{a.sign}</span>)
                            })}
                            </div>
                        </div>
                        <div className={`col-10 col-md-11 m-auto userInfo`}>
                            <div className="row g-0">
                            {
                                user
                                ? 
                                    <>
                                    <div className={`col-11 d-flex justify-content-start align-items-center m-auto`}>
                                        <span className={`userpic`}><img src={user && user.picture? user.picture : user.sex === "female" ? `${femalepic}`: `${malepic}`} alt={user.username} /></span>
                                        <span className={`username`}>{user.username}</span>
                                    </div>
                                    <div className={`col-1 m-auto logout d-flex justify-content-end`}>
                                        <button className={`btn btn-sm btn-secondary`}>Profile</button>
                                        <button className={`btn btn-sm btn-danger`}>Logout</button>

                                    </div>
                                    </>
                                :
                                <div className={`col-12 mx-auto userLogin`}>
                                    <button type="button" className={`btn btn-sm btn-outline-warning singup`}>Sign up</button> or <Link className={`signin`} to={`/signin`}>Login</Link> if you have an account.
                                </div>
                            }
                            </div>
                        </div>
                    </div>
                </div>
                <div className={`top-navbar m-auto`}>
                    <div className="row g-0 m-auto">
                        
                        <div className='col-5 my-auto navbar-elements'>
                            <div className="row g-0">
                                <div title="Home" className={`col-4 m-auto navbar-elm`}>
                                    <Link to={`/`} className={`my-auto navbar-menu-link`}><FontAwesomeIcon title="Home" size="xl" icon="home" /></Link>
                                </div>
                                <div title="Services" className={`col-4 m-auto navbar-elm`}>
                                    <Link to={`/services`} className={`my-auto navbar-menu-link`}><FontAwesomeIcon title="Services" size="xl" icon="handshake" /></Link>
                                </div>
                                <div title="Courts" className={`col-4 m-auto navbar-elm`}>
                                    <Link to={`/courts`} className={`my-auto navbar-menu-link`}><FontAwesomeIcon title="Courts" size="xl" icon="landmark" /></Link>
                                </div>
                            </div>
                        </div>
                        <div className={`col-2 logo`}>
                            <span className={`logo-container`}>
                                <svg version="1.1" id="Hex2" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 90 90"  x="0px" y="0px">
                                    <linearGradient id="grad2" x1="0%" y1="0%" x2="100%" y2="0%">
                                        <stop offset="0%" style={{stopColor:"#c8aa02",stopOpacity:1}} />
                                        <stop offset="50%" style={{stopColor:"#dbc442",stopOpacity:1}} />
                                        <stop offset="100%" style={{stopColor:"#c8aa02",stopOpacity:1}} /> 
                                    </linearGradient>
                                    <g>
                                        <path className="AnimateHex HixHide" stroke="goldenrod" fill="none" d="M2,56.5 2,28.5 2,28.5 28.5,2 28.5,2 58,2 58.5,2 83,28.5 83,28.5 83,56.5 83,56.5 58.5,83 58.5,83 28.5,83z"/>
                                        <path className="hexagon" stroke="orange"  fill="url(#grad2)" d="M55,5 80,30 80,30 80,55 80,55 55,80 55,80 30,80 30,80 5,55 5,55 5,30 5,30 30,5 30,5 55,5z"/>
                                    </g>
                                    <image className={`logo-pic`}  xlinkHref={logo} alt={`Burham Soliman`} linear-gradient="url(#grad2)" x="15" y="15"/>
                                </svg>
                            </span>
                        </div>
                        <div className='col-5 m-auto navbar-elements'>
                            <div className="row g-0">
                                <div title="Mission" className={`col-4 m-auto navbar-elm`}>
                                    <Link to={`/mission`} className={`navbar-menu-link`}><FontAwesomeIcon title="Mission" size="xl" icon="file-lines" /></Link>
                                </div>
                                <div title="Partnership" className={`col-4  m-auto navbar-elm`}>
                                    <Link to={`/partners`} className={`navbar-menu-link`}><FontAwesomeIcon title="Partnership" size="xl" icon="users" /></Link>
                                </div>
                                <div title="Info" className={`col-4 m-auto navbar-elm`}>
                                    <Link to={`/about`} className={`navbar-menu-link`}><FontAwesomeIcon title="Info" size="xl" icon="circle-info" /></Link>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
    )
}

export default Header;