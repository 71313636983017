
const Error = () => {

    return(
        <div className="error-page">
            <div className={`message-box`}>
                <h1 className="message-head">ERROR 404</h1>
                <h2 className="message-body">The requested page is not available at this time, you can try again later or <a href={`#`} >Contact Us</a> with error details</h2>
            </div>
        </div>
    )
}

export default Error;