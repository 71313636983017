import {useParams} from 'react-router-dom'
const User = () => {
const {userid} = useParams()
    return(
        <div className="component">
            <h1>Userid: {userid}</h1>
            <h2>Username: {userid}</h2>
        </div>
    )
}

export default User;