
const Partners = () => {

    return(
        <div className="error-page">
            <h1>Partners</h1>
        </div>
    )
}

export default Partners;