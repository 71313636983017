
const Admin = () => {

    return(
        <div className="component">
            <h1>Admin</h1>
        </div>
    )
}

export default Admin;