const Loader = () => {
    return (
        <>
            <div id="loading">
                <div className="loader">
                    <div className="inner one"></div>
                    <div className="inner two"></div>
                    <div className="inner three"></div>
                    <div className="loading-text">Loading...</div>
                </div>
            </div>
        {
            window.addEventListener('load', () => {
                const mainpage = document.querySelector('.main')
                const loader = document.querySelector('#loading')
                loader.style = 'display:none;'
                mainpage.style = 'display:block;'
            })
        }
        </>
    )
}

export default Loader;